<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                <span class="svg-icon svg-icon-primary svg-icon-2x">
                  <inline-svg src="media/svg/icons/Map/Position.svg" />
                </span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--              <router-link-->
              <!--                to="/locations/list"-->
              <!--                class="btn btn-light-primary font-weight-bolder mr-2"-->
              <!--              >-->
              <!--                <i class="ki ki-long-arrow-back icon-sm"></i>-->
              <!--                All Locations-->
              <!--              </router-link>-->
              <div class="js-submit-options btn-group"></div>
            </div>
          </div>

          <div class="card-body">
            <form v-on:submit.prevent="postEntity">
              <div class="row">
                <div class="col-xl-2"></div>
                <div class="col-xl-8">
                  <div class="my-5">
                    <h4 class="text-dark font-weight-bold mb-10">
                      {{ $t('BILLS.BILL_INFO') }}:
                      <small class="font-italic float-right text-muted"
                        >*{{ $t('FORMS.REQUIRED_FIELDS') }}</small
                      >
                    </h4>
                    <div class="form-group row pb-3">
                      <div class="col-4">
                        <input
                          v-model="bill_data.bill_number"
                          class="form-control form-control-solid"
                          type="text"
                          :placeholder="$t('BILLS.BILL_NUMBER')"
                          required
                        />
                      </div>
                      <div class="col-4">
                        <input
                          v-model="bill_data.business_space_label"
                          class="form-control form-control-solid"
                          type="text"
                          :placeholder="$t('BILLS.BUSINESS_LABEL')"
                          required
                        />
                      </div>
                      <div class="col-4">
                        <input
                          v-model="bill_data.isu_number"
                          class="form-control form-control-solid"
                          type="text"
                          :placeholder="$t('BILLS.ISU_NUMBER')"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div class="my-5">
                    <div class="form-group row">
                      <label class="col-2">{{ $t('GENERAL.TIME') }}:</label>
                      <div class="col-9">
                        <DatePicker
                          v-model="bill_data.bill_time"
                          :masks="masks"
                          is24hr
                          locale="hr"
                          mode="dateTime"
                          required
                        >
                          <template
                            v-slot="{
                              inputValue,
                              inputEvents,
                              // isDragging,
                              // togglePopover,
                            }"
                          >
                            <div class="d-flex">
                              <div>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text">
                                      <i class="la la-calendar"></i>
                                    </span>
                                  </div>
                                  <input
                                    :value="inputValue"
                                    class="form-control form-control-solid form-control-lg"
                                    v-on="inputEvents"
                                  />
                                </div>
                              </div>
                            </div>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-2">JRI:</label>
                      <div class="col-9">
                        <input
                          v-model="bill_data.jir"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="JRI"
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-2">ZKI:</label>
                      <div class="col-9">
                        <input
                          v-model="bill_data.zki"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="ZKI"
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-2">{{ $t('GENERAL.AMOUNT') }}:</label>
                      <div class="col-4">
                        <input
                          v-model="bill_data.amount"
                          class="form-control form-control-solid"
                          type="text"
                          placeholder="0,00 €"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div class="separator separator-dashed my-10"></div>
                  <div class="form-group row">
                    <div class="col text-right">
                      <button
                        type="submit"
                        class="btn btn-primary font-weight-bolder"
                      >
                        <i class="ki ki-check icon-sm"></i>
                        {{ $t('BILLS.ADD_BILL') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
// import ApiService from '@/core/services/api.service'
// import ProfileService from '@/core/services/profile.service'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import formatISO from 'date-fns/formatISO'

export default {
  name: 'BillsNew',
  components: {
    DatePicker,
  },
  data() {
    return {
      bill_data: {
        company_id: this.currentUserCompanyId,
        oib: this.currentUserCompanyOib,
        business_space_label: null,
        isu_number: null,
        bill_number: null,
        bill_time: null,
        bill_time_fmt: null,
        jir: null,
        zki: null,
        amount: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserCompanyOib',
      'currentUserCompanyId',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Add Bill', route: '' }])
  },
  methods: {
    postEntity() {
      this.bill_data.bill_time_fmt = formatISO(this.bill_data.bill_time)
      this.bill_data.oib = this.currentUserCompanyOib
      this.bill_data.company_id = this.currentUserCompanyId

      let endpoint = `company/${this.currentUserCompanyId}/bill-pdf-create`
      const qs = '?' + new URLSearchParams(this.bill_data).toString()
      let pdf_url = `${process.env.VUE_APP_API_ENDPOINT}/${endpoint}${qs}`

      window.open(pdf_url, '_blank') || window.location.replace(pdf_url)
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

input.form-control.form-control-solid::placeholder {
  color: #84909d;
}

.basemap {
  width: 100%;
  height: 300px;
}

.form-control.form-control-solid {
  border-color: #a3b0bd;
}

.form-control.form-control-solid input::placeholder {
  color: #00b300;
}

.form-control {
  &--city-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }

    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }

    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
